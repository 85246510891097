<template>
    <div class="IntanDetails">
        <div class="IntanDetails_top">
            <div>
                <img style="width: 307px;height:206px;" :src="detail.image" alt />
            </div>
            <div class="intanDetails_top_right">
                <p class="intandetails_top_right_top">
                    <span class="intanDetails_top_right_tuwen">图文</span>
                    <span class="intanDetails_top_right_name">{{detail.name || ''}}</span>
                </p>
                <p class="center intandetails_top_right_bottom">
                    <span class="intanDetails_top_right_yuedu font_style">{{detail.view || 0}}人次阅读</span>
                    <span
                        class="intanDetails_top_right_time font_style"
                    >{{detail.create_time || ''}}</span>
                </p>
            </div>
        </div>

        <div class="intanDetails_bottom">
            <div class="intanDetails_bottom_brief">简介</div>
            <div class="intanDetails_bottom_text" v-html="parseRichImgStyle(detail.detail)">
                <img src="../assets/image/fuwenben1.png" alt />
                <img src="../assets/image/fuwenben1.png" alt />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: "",
            detail: {},
        };
    },
    created() {
        this.id = this.$route.query.id;
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        //   获取详情
        getDetail() {
            this.$axios
                .post(this.$api.culturalDetail, { id: this.id })
                .then((res) => {
                    if (res.code == 1) {
                        this.detail = res.data;
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.IntanDetails {
    width: 1200px;
    margin: 0 auto;
    .IntanDetails_top {
        padding: 33px 30px;
        display: flex;
        border: 1px solid #f2f2f2;
        margin-bottom: 20px;

        .intanDetails_top_image {
            width: 370px;
            height: 277px;
        }

        .intanDetails_top_right {
            margin-left: 26px;
            .intandetails_top_right_top {
                margin-bottom: 30px;
                .intanDetails_top_right_tuwen {
                    display: inline-block;
                    background: #eaf1ff;
                    width: 42px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-size: 14px;
                    color: #1263ff;
                    border-radius: 2px;
                }
                .intanDetails_top_right_name {
                    font-size: 24px;
                    margin-left: 10px;
                    font-weight: bold;
                    font-family: "MicrosoftYaHei-Bold";
                }
            }
            .intandetails_top_right_bottom {
                .font_style {
                    color: #999999;
                    font-family: "MicrosoftYaHei";
                    font-size: 14px;
                }
                .intanDetails_top_right_time {
                    margin-left: 30px;
                }
            }
        }
    }

    .intanDetails_bottom_brief {
        background: #fafafa;
        height: 78px;
        line-height: 78px;
        text-align: center;
        font-size: 20px;
        color: #333333;
    }
    .intanDetails_bottom_text {
        background: #ffffff;
        padding: 30px;
    }
}
</style>
